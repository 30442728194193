<!--Cabecera-->
<div class="row justify-content-center align-items-center my-2">
  <div class="col-9 col-md-10 text-center">
    <h5 class="m-0 p-0">
      Formas de Pago
      <small class="text-muted"></small>
    </h5>
  </div>
  <div
    class="col-2 col-md-1 justify-content-center align-items-center"
    (click)="cerrarModal()"
  >
    <button type="button" class="close pull-right" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-4 mt-1">
      <div class="card" style="max-width: 100%" (click)="savefp('Efectivo')">
        <div class="row no-gutters">
          <div class="col-5">
            <img
              class="img-fluid m-2"
              src="https://novaexpress.group/img/logos/pez_express_color_cuadrado.svg"
            />
          </div>
          <div class="col-7">
            <div class="card-body">
              <h5 class="card-title">Efectivo</h5>
              <p class="card-text"></p>
              <p class="card-text">
                <small class="text-muted">Cajera procedera con cobro</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-4 my-1" *ngIf="cliente.slice(0, 10)!=='9'.repeat(10)">
      <div
        class="card"
        style="max-width: 100%; background-color: #ffac00"
        (click)="savefp('Pay Phone')"
      >
        <div class="row no-gutters">
          <div
            class="col-5 d-flex justify-content-center align-items-center text-center"
          >
            <img
              class="img-fluid m-2"
              src="https://www.payphone.app/media/ffomkhkv/logopayphonenew.svg"
            />
          </div>
          <div class="col-7" style="color: #0f265c">
            <div class="card-body">
              <h5 class="card-title">Pay Phone</h5>
              <p class="card-text p-0 m-0">Tarjeta Credito</p>
              <p class="card-text p-0 m-0">Tarjeta Debito</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
<!--
    <div class="col-12 col-md-4 my-1">
      <div
        class="card"
        style="max-width: 100%; background-color: #ffffff"
        (click)="savefp('BiVi')"
      >
        <div class="row no-gutters">
          <div class="col-5 d-flex align-items-center">
            <img
              class="img-fluid m-2"
              src="https://www.biviapp.com/assets/img/logo_bivi.png"
            />
          </div>
          <div class="col-7" style="color: #ea8e29">
            <div class="card-body">
              <h5 class="card-title">BiVi</h5>
              <p class="card-text p-0 m-0">Tarjeta Credito</p>
              <p class="card-text p-0 m-0">Tarjeta Debito</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-4 my-1">
      <div
        class="card"
        style="max-width: 100%; background-color: #ffffff"
        (click)="savefp('De Una')"
      >
        <div class="row no-gutters">
          <div class="col-5 d-flex align-items-center">
            <img
              class="img-fluid m-2"
              src="https://deuna.app/assets/images/deuna-logo.svg"
            />
          </div>
          <div class="col-7" style="color: #432959">
            <div class="card-body">
              <h5 class="card-title">De Una</h5>
              <p class="card-text p-0 m-0">Banco Pichincha</p>
              <p class="card-text p-0 m-0">Efectivo Movil</p>
            </div>
          </div>
        </div>
      </div>
    </div>

     BANCO PICHINCHA
    <div class="col-12 col-md-4 my-1">
      <div
        class="card"
        style="max-width: 100%; background-color: #ffdd00"
        (click)="savefp('Banco Internacional')"
      >
        <div class="row no-gutters">
          <div class="col-5 d-flex align-items-center">
            <img
              class="img-fluid m-2"
              src="https://www.pichincha.com/portal/Portals/0/MainPichincha.svg"
            />
          </div>
          <div class="col-7" style="color: #0f265c">
            <div class="card-body">
              <h5 class="card-title">Banco Interna</h5>
              <p class="card-text p-0 m-0">Cuenta Corriente</p>
              <p class="card-text p-0 m-0">12 34 56 78 90</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-4 mt-1 mb-3">
      <div
        class="card"
        style="max-width: 100%; background-color: #000000"
        (click)="savefp('Banco Internacional')"
      >
        <div class="row no-gutters">
          <div class="col-5 d-flex align-items-center">
            <img
              class="img-fluid m-2"
              src="https://www.bancointernacional.com.ec/wp-content/uploads/2020/10/logo-bi.png"
            />
          </div>
          <div class="col-7" style="color: white">
            <div class="card-body">
              <h5 class="card-title">Banco Internacional</h5>
              <p class="card-text p-0 m-0">Cuenta Ahorros</p>
              <p class="card-text p-0 m-0">14 10 81 49 71</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    -->  
  </div>
</div>
