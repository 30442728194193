import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ModalLineasComponent } from '../../modal-lineas/modal-lineas.component';
import { ModalFormapagoComponent } from '../../modal-formapago/modal-formapago.component';
import { ModalPedidoRecetaComponent } from '../../modal-pedido-receta/modal-pedido-receta.component';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';

import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

import { GeolocalizacionService } from 'src/app/services/geolocalizacion.service';

import { ModalClienteaddComponent } from '../../modal-clienteadd/modal-clienteadd.component';

import { ModalTipoServicioComponent } from '../../ventas/modal-tipo-servicio/modal-tipo-servicio.component';

import { ModalLocalComponent } from '../../ventas/modal-local/modal-local.component';
import { ModalNumeroMesaComponent } from '../../ventas/modal-numero-mesa/modal-numero-mesa.component';

@Component({
  selector: 'app-factura',
  templateUrl: './factura.component.html',
  styleUrls: ['./factura.component.css'],
})
export class FacturaComponent implements OnInit {
  //Referencia del modal
  modalReferencia: BsModalRef;
  modalReferenciaLineas: BsModalRef;
  ModalReferenciaPedidoReceta: BsModalRef;
  modalReferenciaFormaPago: BsModalRef;

  ModalTipoServicio: BsModalRef;
  ModalLocal: BsModalRef;
  ModalNumeroMesa: BsModalRef;

  //Cliente traido del modal
  cliente: any = {};
  clienteold: any = {};
  
  //Lineas traidas de la DB
  lineas: any = [];
  //Datos del localStorage
  datosPersistentes: any = [];
  //Productos desde localStorage
  productos: any = [];
  mov_prod: any = [];
  productoslinea: any = [];
  //Productos del consumidor final
  productosConsumidorFinal: any = [];

  clienteCollapse = 'Datos del Cliente';
  efectivo: any = [0, 0, 0, 0, 0]; //<--Efectivo temporal
  efectivoColor: any = [true, false, false, false, false]; //<--Efectivo temporal
  cantpro = 0;
  //efectivo:any = []
  cambio: number = 0; //<--efectivo-total
  efectivoEscogido: number = 0; //<--Valor efectivo escogido
  //Bandera para spinner
  banderaSpinner: boolean = false;
  //RUC de consumidor final
  rucConsumidorFinal: string = '9'.repeat(13);

  nomlinact = '';
  comentario: string = '';
  lofp : string = '';
  ceduladig: string = '';
  nombredig: string = '';
  whatsapp: string = '';

  cupon: string = '';
  aplicacupon: string = '';
  pordescupon: number = 0;
  valdescupon: number = 0;

  ventana: any;

  sucursales: any;
  nomsucursal: string = '';
  sucursal: string = '';

  admin: boolean = false;
  empresa: string = '001';

  datosempresa: any = {
    nombre: '',
    color: '',
    urlLogo: '',
    cal_artesanal: '',
    acceso: '',
  };

  tiposentregas: any;
  tipoentrega: string = '';
  nomservicio: string = '';

  serviciosentregas: any;
  servicioentrega: string = '001';
  

  formaspagos: any;
  formapago: string = '';
  strformapago: string = '';

  sumatoria: number = 0;
  descuento: number = 0;
  tarifa0: number = 0;
  tarifa12: number = 0;

  subtotal: number = 0; 
  impuesto: number = 0; 
  total: number = 0; 
  mesa: number = 0;
  menu: string = '001';

  constructor(
    private router: Router,
    private modalServicio: BsModalService,
    private servicio: RestauranteService,
    private modalServicioLineas: BsModalService,
    private modalServicioFormaPago: BsModalService,
    private modalServicioPedidoReceta: BsModalService,

    private modalServicioTipoServicio: BsModalService,
    private modalServicioLocal: BsModalService,
    private modalServicioNumeroMesa: BsModalService,

    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private GeolocalizacionService: GeolocalizacionService
  ) {}

  ngOnInit(): void {
    localStorage.setItem('cliente', JSON.stringify('9'.repeat(13)));
    this.route.queryParams.subscribe((params: Params) => {
      if (Object.entries(params).length === 0) {
        // SIN PARAMETROS
        this.empresa = '001';
        this.sucursal = '002';
        this.mesa = 0;
      } else {
        if (params.empresa != 'undefined') {
          this.empresa = params.empresa;
        }
        if (params.sucursal != 'undefined') {
          this.sucursal = params.sucursal;
        }
        if (params.mesa != 'undefined') {
          this.mesa = params.mesa;
        }
      }
    });


    this.limpiacampos();
    console.log(localStorage.getItem('GPS'));

    //this.empresa = '001';
    this.rucConsumidorFinal = '9'.repeat(13);
    this.ceduladig = '';
    
    this.buscar_modales()
    /* falta implementar
    if (!localStorage.getItem('usuario')) this.router.navigate(['login']);

    if (localStorage.getItem('empresa'))
      this.datosempresa = JSON.parse(localStorage.getItem('empresa'));
    if (JSON.parse(localStorage.getItem('empresa')) !== null) {
      this.empresa = JSON.parse(localStorage.getItem('empresa'))['codigo'];
    }
    if (JSON.parse(localStorage.getItem('sucursal')) !== null) {
      this.sucursal = JSON.parse(localStorage.getItem('sucursal'))['codigo'];
    }
*/

    setInterval(() => {
      if (this.banderaSpinner === true) {
        this.spinner.hide();
        this.banderaSpinner = false;
      }
    }, 100);

    this.traerConsumidorFinal();

    this.cargarSucursales();

    this.cargarTipoEntrega();

    /*
    this.cargarServicioEntrega();
    this.cargarFormaPago();
*/
    this.cambio = this.calcularCambio();

    /*
    this.admin = JSON.parse(localStorage.getItem('usuario')).admin;
    this.sucursal = JSON.parse(localStorage.getItem('sucursal')).codigo;
    */
  }

  //Abre el modal
  /*
  abrirModalBuscarCliente() {
    //Este modal recibe el RUC del cliente que usa data binding componente hacia template
    const initialState: any = {
      //<--Lo que va a recibir el modal
      datos: [
        //<--Datos enviados desde componente hacia modal
        this.cliente.ruc,
      ],
    };
    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferencia = this.modalServicio.show(ModalFacturaComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-md',
    });
    //Al cerrar el modal recibe la data
    this.modalReferencia.content.event.subscribe((res) => {
      this.cliente = res.data;
      this.clienteCollapse = this.cliente.nombre.trim();
      this.cargarDatosStorage();
    });
  }
*/
  buscar_modales(){
    if (JSON.parse(localStorage.getItem('tiposervicio')) !== null) {
      this.tipoentrega = JSON.parse(localStorage.getItem('tiposervicio'))['codigo'];
      this.nomservicio = JSON.parse(localStorage.getItem('tiposervicio'))['nombre']
    }else{
      this.mostrarModalTipoServicio()
    }

    if (JSON.parse(localStorage.getItem('sucursal')) !== null) {
      this.sucursal = JSON.parse(localStorage.getItem('sucursal'))['codigo'];
      this.nomsucursal = JSON.parse(localStorage.getItem('sucursal'))['nombre']
    }

    if (JSON.parse(localStorage.getItem('mesa')) !== null) {
      this.mesa = JSON.parse(localStorage.getItem('mesa'));
    }
  }
  buscar_descuento() {
    let datos = {
      empresa : this.empresa,
      cupon : this.cupon
    }
    this.servicio
      .buscarCupon(datos)
      .subscribe(
        (result) => {
          console.log(result)
          if (result.length > 0) {
            this.pordescupon = result[0].porc;
            this.valdescupon = Number(
              (this.subtotal * (this.pordescupon / 100)).toFixed(2)
            );

            this.alerta(
              'Recompensa de $ ' + this.valdescupon,
              'Hola, que bueno que estes al dia en nuestras promociones',
              'success'
            );
            this.aplicacupon = this.cupon;
            this.cupon = '';

            this.calculaTotales();
            this.actualizarEfectivo();
          } else {
            this.alerta(
              'Cupon no Valido',
              'Dirígete a caja y consulta nuestras promociones mas recientes',
              'error'
            );
            this.cupon = '';
            this.aplicacupon = '';
          }
        },
        (err) => {
          this.banderaSpinner = true;
          this.alerta(
            'Error de conexión',
            'Se perdió la conexión con el servidor',
            'error'
          );
          console.log(err);
        }
      );
  }

  quitar_descuento() {
    this.cupon = '';
    this.aplicacupon = '';
    this.pordescupon = 0;
    this.valdescupon = 0;
    this.calculaTotales();
  }

  save_formapago() {
    if (this.formapago != '001') {
      this.cambio = 0;
      this.efectivoEscogido = 0;
    }

    for (let clave in this.formaspagos) {
      if (this.formaspagos[clave].codigo.trim() == this.formapago.trim())
        this.strformapago = this.formaspagos[clave].nombre.trim();
    }
  }

  //Abre el modal de linea
  mostrarModalLinea(idactual) {
    for (var i = 0; i < this.lineas.length; i++) {
      if (this.lineas[i].codigo.trim() === idactual.trim())
        this.nomlinact = this.lineas[i].nombre.trim();
    }

    const initialState: any = {
      datos: [idactual, this.cliente.ruc.toString().trim(), this.nomlinact],
    };

    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.modalReferenciaLineas = this.modalServicioLineas.show(
      ModalLineasComponent,
      {
        initialState,
        ignoreBackdropClick: true,
        class: 'modal-completo',
      }
    );
    //Al cerrar el modal recibe la data
    this.modalReferenciaLineas.content.event.subscribe(() => {
      console.log('MODAL DE LINEAS CERRADO');
      //Vemos si hay productos y los traemos
      this.cargarDatosStorage();
    });
  }

  mostrarModalFormaPago() {
    return new Promise<void>((resolve,reject)=>{
      const initialState: any = {
        datos: [this.cliente.ruc.toString().trim(), this.nomlinact],
      };
      this.modalReferenciaFormaPago = this.modalServicioLineas.show(
        ModalFormapagoComponent,
        {
          initialState,
          ignoreBackdropClick: true,
          class: 'modal-completo',
        }
      );
      this.modalReferenciaFormaPago.content.event.subscribe(() => {
        console.log('MODAL DE FORMA DE PAGO CERRADO');
        this.formapago = JSON.parse(localStorage.getItem('formapago'));
        switch (this.formapago) {
          case 'Pay Phone':
            this.lofp = '008';
            break;
          case 'DeUna':
            this.lofp = '002';
          
            break;
          case 'Banco Pichincha':
            this.lofp = '002';
            break;
          case 'Banco Guayaquil':
            this.lofp = '002';
            break; 
          case 'Banco Internacional':
            this.lofp = '002';
            break; 
          default:
            this.lofp = '001';
        }
        resolve();
      });
    })
    
  }

  mostrarModalTipoServicio() {
    const initialState: any = {};
    this.ModalTipoServicio = this.modalServicioTipoServicio.show(
      ModalTipoServicioComponent,
      {
        initialState,
        ignoreBackdropClick: true,
        class: 'modal-completo',
      }
    );
    this.ModalTipoServicio.content.event.subscribe(() => {
      //console.log('MODAL DE TIPO SERVICIO CERRADO');
      this.tipoentrega = JSON.parse(localStorage.getItem('tiposervicio'))['codigo'];
      this.nomservicio = JSON.parse(localStorage.getItem('tiposervicio'))['nombre']
      this.mostrarModalSucursal()
      
    });
  }

  mostrarModalSucursal() {
    const initialState: any = {};

    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.ModalLocal = this.modalServicioLocal.show(
      ModalLocalComponent,
      {
        initialState,
        ignoreBackdropClick: true,
        class: 'modal-completo',
      }
    );
    //Al cerrar el modal recibe la data
    this.ModalLocal.content.event.subscribe(() => {
      //console.log('MODAL DE SUCURSAL CERRADO');
      this.sucursal = JSON.parse(localStorage.getItem('sucursal'))['codigo'];
      this.nomsucursal = JSON.parse(localStorage.getItem('sucursal'))['nombre']
           
      if(this.tipoentrega === '001' && this.mesa === 0 ){
        this.mostrarModalMesa();
    }
    });
  }

  mostrarModalMesa() {
    const initialState: any = {};

    //Asigna la referencia usando el servicio propio del modal
    //Se envia el componente modal, con su estado inicial y con background click inactivo
    this.ModalNumeroMesa = this.modalServicioNumeroMesa.show(
      ModalNumeroMesaComponent,
      {
        initialState,
        ignoreBackdropClick: true,
        class: 'modal-completo',
      }
    );
    //Al cerrar el modal recibe la data
    this.ModalNumeroMesa.content.event.subscribe(() => {
      console.log('MODAL DE NUMERO CERRADO');
      this.mesa = JSON.parse(localStorage.getItem('mesa'));
    });
  }

  //Restar cantidad
  restarCantidad(id) {
    let producto = this.buscarProducto(id);
    if (producto[0].cantidad > 0) {
      producto[0].cantidad--;
      this.guardaLocalStorage(producto[0], 'resta');
      this.guardarDatosStorage(producto[0]);
    }
  }

  //Aumentar cantidad
  aumentarCantidad(id) {
    let producto = this.buscarProducto(id);
    producto[0].cantidad++;
    this.actualizarEfectivo();
    this.guardaLocalStorage(producto[0], 'suma');
    this.guardarDatosStorage(producto[0]);
    console.log('PRODUCTOS', this.productos);
  }

  //Busca producto en array de productos y lo devuelve
  buscarProducto(id) {
    const item = this.productos.filter(function (objeto) {
      return objeto.codigo === id;
    });

    return item;
  }

  async grabar(){
    await this.mostrarModalFormaPago();
    await this.realizarTransaccion();
  }

  //Realizar la transaccion
  async realizarTransaccion() {
    //Llenando cabecera
    let cabecera = {
      empresa: this.empresa,
      sucursal: this.sucursal,
      modulo: '008',
      tipo: '002',
      ruc: this.cliente.ruc.toString().trim(),
      comentario: this.comentario.trim(),
      cambio: this.cambio,
      efectivo: this.efectivoEscogido,
      usuario: '999',
      mesa: this.mesa,
      tipoentrega: this.tipoentrega,
      servicioentrega: this.servicioentrega,
      formapago: this.lofp,
      distribuidor : '',
      sumatoria: this.sumatoria,
      descuento: this.descuento,
      tarifa0: this.tarifa0,
      tarifa12: this.tarifa12,
      subtotal: this.subtotal,
      impuesto: this.impuesto,
      total: this.total,
      cu_cupon : this.aplicacupon,
      cu_porc : this.pordescupon,
      cu_valor : this.valdescupon
    };

    console.log(cabecera)
    //Llenando cuerpo

    let cuerpo = [];

    this.productos.forEach((element) => {
      if (element.cantidad > 0) cuerpo.push(element);
    });

    await this.verificarRecetas();

    //Construyendo solicitud
    let datos = { cabecera, cuerpo };
    console.log('DATA ENVIADA', datos);
    
    this.spinner.show();
    this.servicio.efectuarPedido(datos).subscribe(
      (data) => {
        this.banderaSpinner = true;
        console.log('DATA RECIBIDA DE SQL POR TRANSACCION:', data);

        if (!(Object.keys(data).length === 0)) {
          this.alerta(
            '¡Prepararemos tus Platillos!',
            this.nomservicio+' PEDIDO '+this.nomsucursal+' # ' + data.numero,
            'success',
            1500
          ).then(async () => {
            if (this.lofp === '008'){
              await this.solicitarPP(data.numero, this.cliente,this.total);
            }

            localStorage.removeItem(this.cliente.ruc.toString().trim());
            this.resetpedido()
            this.quitar_descuento();
            this.limpiacampos();
            this.traerConsumidorFinal();
            this.buscar_modales();
          });
        } else {
          this.alerta(
            'Transacción no realizada',
            'Ha ocurrido un error inesperado',
            'error'
          );
        }
        
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  //Borrar producto
  borrarItem(codigo) {
    let producto = this.buscarProducto(codigo);
    if (producto[0].cantidad > 0) {
      producto[0].cantidad = 0;

      //Actualizar efectivos
      this.actualizarEfectivo();
      //Guardando en localStorage
      this.guardarDatosStorage(producto[0]);
    }
    this.cantpro = this.cantpro - 1;
  }

  pintarEfectivo(numero) {
    this.efectivoColor = [false, false, false, false, false];
    this.efectivoColor[numero - 1] = true;
    switch (numero) {
      case 1:
        this.efectivoEscogido = this.efectivo[0];
        break;
      case 2:
        this.efectivoEscogido = this.efectivo[1];
        break;
      case 3:
        this.efectivoEscogido = this.efectivo[2];
        break;
      case 4:
        this.efectivoEscogido = this.efectivo[3];
        break;
      case 5:
        this.efectivoEscogido = this.efectivo[4];
        break;
    }
    this.actualizarEfectivo();
  }

  actualizarEfectivo() {
    this.efectivo = [0, 0, 0, 0, 0];

    // 1) Primer boton (total)
    this.efectivo[0] = this.total;
    let totalText = this.total.toFixed(2).toString();
    let entero = totalText.split('.')[0];
    let decimal = totalText.split('.')[1];

    // 2) Segundo boton (redondeo por exceso)
    if (parseInt(decimal) > 0) {
      this.efectivo[1] = parseInt(entero) + 1;
    } else {
      this.efectivo[1] = 0;
    }

    // 3) Tercer boton (redondeo decimales)
    let decimalTercerBoton = decimal;
    let enteroTercerBoton = entero;
    if (parseInt(decimalTercerBoton) > 0 && parseInt(decimalTercerBoton) < 25) {
      decimalTercerBoton = '25';
    } else {
      if (
        parseInt(decimalTercerBoton) > 25 &&
        parseInt(decimalTercerBoton) < 50
      ) {
        decimalTercerBoton = '50';
      } else {
        if (
          parseInt(decimalTercerBoton) > 50 &&
          parseInt(decimalTercerBoton) < 75
        ) {
          decimalTercerBoton = '75';
        } else {
          if (parseInt(decimalTercerBoton) > 75) {
            decimalTercerBoton = '00';
            enteroTercerBoton = (parseInt(enteroTercerBoton) + 1).toString();
          }
        }
      }
    }
    this.efectivo[2] = parseFloat(enteroTercerBoton + '.' + decimalTercerBoton);
    // 4) y 5) Cuarto y quinto boton (primer y segundo valor multiplo)
    let enteroCuartoBoton = entero;
    let enteroQuintoBoton = entero;
    if (enteroCuartoBoton.length === 1) {
      enteroCuartoBoton = '10';
      enteroQuintoBoton = '20';
    } else {
      if (enteroCuartoBoton.length === 2) {
        enteroCuartoBoton = (
          parseInt(enteroCuartoBoton[0] + '0') + 10
        ).toString();
        enteroQuintoBoton = (
          parseInt(enteroQuintoBoton[0] + '0') + 20
        ).toString();
      }
      if (enteroCuartoBoton.length === 3) {
        enteroCuartoBoton = (
          parseInt(enteroCuartoBoton[0] + '00') + 10
        ).toString();
        enteroQuintoBoton = (
          parseInt(enteroQuintoBoton[0] + '00') + 20
        ).toString();
      }

      if (enteroCuartoBoton.length === 4) {
        enteroCuartoBoton = (
          parseInt(enteroCuartoBoton[0] + '000') + 10
        ).toString();
        enteroQuintoBoton = (
          parseInt(enteroQuintoBoton[0] + '000') + 20
        ).toString();
      }

    }
    this.efectivo[3] = parseFloat(enteroCuartoBoton + '.00');
    this.efectivo[4] = parseFloat(enteroQuintoBoton + '.00');
    //Borrando duplicados en arreglo efectivo
    this.efectivo = [...new Set(this.efectivo)];
    //Calculamos el cambio
    this.cambio = this.calcularCambio();
  }

  calcularCambio() {
    let cambiocal: number = 0;
    cambiocal = this.efectivoEscogido - this.total;
    if (cambiocal < 0) cambiocal = 0;
    return cambiocal;
  }

  //---METODOS DE MANEJO DEL LOCALSTORAGE (MANEJAR CON PRUDENCIA)---

  //Guardar en el localStorage
  guardarDatosStorage(producto) {
    this.datosPersistentes = JSON.parse(
      localStorage.getItem(this.cliente.ruc.toString().trim())
    );
    console.log('guardarDatosStorage', this.datosPersistentes);
    this.datosPersistentes.forEach((item) => {
      if (item.linea === producto.linea) {
        let itemsLinea = item.productos;
        itemsLinea.forEach((itemLinea) => {
          if (itemLinea.codigo === producto.codigo) {
            itemLinea.cantidad = producto.cantidad;
          }
        });
      }
    });

    localStorage.setItem(
      this.cliente.ruc.toString().trim(),
      JSON.stringify(this.datosPersistentes)
    );
    //Actualiza la variable suma temporal
    this.calculaTotales();
    this.actualizarEfectivo();
    this.pintarEfectivo(1);
    return;
  }

  //Guarda en el localStorage
  guardaLocalStorage(producto, operacion) {
    console.log('guardaLocalStorage', this.cliente);
    //No existe el objeto en LocalStorage
    if (!localStorage.getItem(this.cliente.ruc.toString())) {
      //<--No existe nada y creamos el primer item
      let info = {
        linea: producto.linea,
        productos: [
          {
            codigo: producto.codigo,
            nombre: producto.nombre,
            precio: producto.precio,
            cantidad: producto.cantidad,
            porc_tarifa: producto.porc_tarifa,
            medida: producto.medida,
            descuento: producto.descuento,
          },
        ],
      };
      this.datosPersistentes.push(info);
      localStorage.setItem(
        this.cliente.ruc.toString(),
        JSON.stringify(this.datosPersistentes)
      );
    }
    //Existe el objeto en LocalStorage
    else {
      this.datosPersistentes = JSON.parse(
        localStorage.getItem(this.cliente.ruc.toString())
      );
      //console.log(this.datosPersistentes);
      let control = false; //<--Iniciamos asumiendo que la linea no existe en localStorage
      this.datosPersistentes.forEach((item) => {
        //console.log(item);
        if (item.linea === producto.linea) {
          control = true; //<--La linea si existe
          let itemsLinea = item.productos;
          let control2 = false; //<--Iniciamos asumiendo que el producto no existe
          itemsLinea.forEach((itemLinea) => {
            if (itemLinea.codigo === producto.codigo) {
              control2 = true;
              itemLinea.cantidad = producto.cantidad;
            }
          });
          //Si control2 es true el producto ya existe y fue procesado arriba, caso contrario lo agregamos aqui
          if (control2 === false) {
            itemsLinea.push({
              codigo: producto.codigo,
              nombre: producto.nombre,
              precio: producto.precio,
              cantidad: producto.cantidad,
              porc_tarifa: producto.porc_tarifa,
              medida: producto.medida,
              descuento: producto.descuento,
            });
          }
        }
      });
      //Si control es true la linea ya existe y fue procesada arriba, caso contrario la agregamos aqui
      if (control === false) {
        let info = {
          linea: producto.linea,
          productos: [
            {
              codigo: producto.codigo,
              nombre: producto.nombre,
              precio: producto.precio,
              cantidad: producto.cantidad,
              porc_tarifa: producto.porc_tarifa,
              medida: producto.medida,
              descuento: producto.descuento,
            },
          ],
        };
        this.datosPersistentes.push(info);
      }
    }
    localStorage.setItem(
      this.cliente.ruc.toString(),
      JSON.stringify(this.datosPersistentes)
    );
    //Actualiza la variable suma temporal
    if (operacion === 'suma') {
      this.total = Number(this.total) + Number(producto.precio);
    }
    if (operacion === 'resta') {
      this.total = Number(this.total) - Number(producto.precio);
    }
    return;
  }

  //Traemos los datos del localStorage en el caso de presionar el buscador

  cargarDatosStorage() {
    //this.productos = []; //<-- Traemos los datos para el cliente actual
    this.limpiacampos();
    console.log(
      'cargarDatosStorage',
      localStorage.getItem(this.cliente.ruc.toString().trim())
    );
    // valida el NULL
    if (localStorage.getItem(this.cliente.ruc.toString().trim())) {
      this.pasarDatosClienteaotroCliente();
      this.cargaDatosPersistentes();
      this.pintarEfectivo(1);
    } else {
      // CREA EL LS EN BLANCO
      this.datosPersistentes = [];
      localStorage.setItem(
        this.cliente.ruc.toString().trim(),
        JSON.stringify(this.datosPersistentes)
      );

      this.pasarDatosClienteaotroCliente();
      this.cargaDatosPersistentes();
    }
    this.calculaTotales();
    this.actualizarEfectivo();
  }

  limpiacampos() {
    this.cantpro = 0;
    this.efectivoEscogido = 0;
    this.cambio = 0;
    this.sumatoria = 0;
    this.tarifa0 = 0;
    this.tarifa12 = 0;
    this.descuento = 0;
    this.subtotal = 0;
    this.impuesto = 0;
    this.total = 0;



  }

  resetpedido(){
    this.productos = [];
    this.tipoentrega = ''
    this.nomservicio = ''
    this.sucursal = ''
    this.nomsucursal = ''
    this.mesa = 0
    this.comentario = '';
    this.servicioentrega = '';
    this.formapago = '002';
    this.rucConsumidorFinal = '9'.repeat(13);

    localStorage.removeItem('formapago');
    localStorage.removeItem('mesa');
    localStorage.removeItem('sucursal');
    localStorage.removeItem('tiposervicio');
  }
  // cuando se busca un cliente
  cargaDatosPersistentes() {
    //console.log('PRODUCTOS', this.productos);
    //Ahora carga los datos persistentes
    this.datosPersistentes = [];
    this.datosPersistentes = JSON.parse(
      localStorage.getItem(this.cliente.ruc.toString().trim())
    );

    console.log('datosPersistentes', this.datosPersistentes);

    if (this.datosPersistentes != null) {
      this.datosPersistentes.forEach((dato) => {
        dato.productos.forEach((item) => {
          console.log('dato.productos.forEach', item);
          if (item.cantidad > 0) {
            // BUSCAR EL PRODUCTO
            let producto = this.buscarProducto(item.codigo);
            console.log('busca producto', producto);
            // CARGARLE LA CANTIDAD
            producto[0].cantidad = item.cantidad;
            producto[0].receta = [];
            producto[0].comentario = item.cantidad + ' Completo';
          }
          /*
          if (item.cantidad > 0) {
            let comentario = item.cantidad + ' Completo';
            this.productos.push({
              codigo: item.codigo,
              nombre: item.nombre,
              precio: item.precio,
              cantidad: item.cantidad,
              linea: dato.linea,
              receta: [],
              comentario,
              porc_tarifa: item.porc_tarifa,
              descuento: item.descuento,
              medida: item.medida,
            });

            this.cantpro = this.cantpro + 1;
          }
          */
        });
      });
    } else {
      this.datosPersistentes = [];
      localStorage.setItem(
        this.cliente.ruc.toString().trim(),
        JSON.stringify(this.datosPersistentes)
      );
    }
  }

  pasarDatosClienteaotroCliente() {
    if (!(Object.keys(this.clienteold).length === 0)) {
      if (
        this.clienteold.ruc.toString().trim() !=
        this.clienteold.rucanterior.toString().trim()
      ) {
        //<--Solo tiene sentido si es otro cliente
        //Primero actualiza los datos persistentes
        this.datosPersistentes = JSON.parse(
          localStorage.getItem(this.cliente.ruc.toString().trim())
        );
        if (this.datosPersistentes != null) {
          if (localStorage.getItem(this.clienteold.rucanterior.trim())) {
            let datosConsumidorFinal = JSON.parse(
              localStorage.getItem(this.clienteold.rucanterior.trim())
            );

            for (let i = 0; i < datosConsumidorFinal.length; i++) {
              let existeLinea = -1; //<--Indica si la linea existe en datos (indice en consumidor final)
              let linea = datosConsumidorFinal[i].linea; //<--Itera cada una de las lineas de consumidor final
              for (let j = 0; j < this.datosPersistentes.length; j++) {
                if (linea === this.datosPersistentes[j].linea) {
                  existeLinea = j;
                  break;
                }
              }

              //La linea existe
              if (existeLinea > -1) {
                let productosConsumidorFinal =
                  datosConsumidorFinal[i].productos; //<--Productos consumidor final
                let productosCliente =
                  this.datosPersistentes[existeLinea].productos; //<--Productos nuevo cliente
                for (let k = 0; k < productosConsumidorFinal.length; k++) {
                  let existeProducto = -1; //<--Indica si el producto existe en datos (indice en consumidor final)
                  for (let m = 0; m < productosCliente.length; m++) {
                    if (
                      productosConsumidorFinal[k].codigo ===
                      productosCliente[m].codigo
                    ) {
                      //Buscamos si el producto existe
                      existeProducto = m;
                      break;
                    }
                  }
                  //El producto existe
                  if (existeProducto > -1) {
                    productosCliente[existeProducto].cantidad =
                      productosCliente[existeProducto].cantidad +
                      productosConsumidorFinal[k].cantidad;
                  }
                  //El producto no existe
                  else {
                    productosCliente.push(productosConsumidorFinal[k]);
                  }
                }
              }
              //La linea no existe
              else {
                this.datosPersistentes.push(datosConsumidorFinal[i]);
              }
            }
          }
        } else {
          this.datosPersistentes = [];
        }
        localStorage.setItem(
          this.cliente.ruc.toString().trim(),
          JSON.stringify(this.datosPersistentes)
        );
        localStorage.removeItem(this.clienteold.rucanterior.trim());
      }
    }
    /*
    this.productos = []; //<-- Traemos los datos para el cliente actual
    this.limpiacampos();
    if (this.datosPersistentes != null) {
      this.datosPersistentes.forEach((dato) => {
        dato.productos.forEach((item) => {
          if (item.cantidad > 0) {
            let comentario = item.cantidad + ' Completo';
            this.productos.push({
              codigo: item.codigo,
              nombre: item.nombre,
              precio: item.precio,
              cantidad: item.cantidad,
              linea: dato.linea,
              receta: [],
              comentario,
              porc_tarifa: item.porc_tarifa,
              descuento: item.descuento,
              medida: item.medida,
            });
            this.cantpro = this.cantpro + 1;
          }
        });
      });
    }
    */
  }

  //---FNAL ZONA LOCALSTORAGE---

  //Abrir el Collapsable del cliente
  abrirCollapseCliente() {
    if (
      document.getElementById('datos_cliente_icon').className ==
      'fas fa-chevron-up'
    ) {
      document.getElementById('datos_cliente_contenido').style.display = 'none';

      document.getElementById('datos_cliente_icon').className =
        'fas fa-chevron-down';
    } else {
      document.getElementById('datos_cliente_contenido').style.display =
        'block';

      document.getElementById('datos_cliente_icon').className =
        'fas fa-chevron-up';
    }
  }

  //Abrir el Collapsable del cliente
  abrirCollapseTotales() {
    if (
      document.getElementById('datos_totales_icon').className ==
      'fas fa-chevron-up'
    ) {
      document.getElementById('datos_totales_contenido').style.display = 'none';

      document.getElementById('datos_totales_icon').className =
        'fas fa-chevron-down';
    } else {
      document.getElementById('datos_totales_contenido').style.display =
        'block';

      document.getElementById('datos_totales_icon').className =
        'fas fa-chevron-up';
    }
  }

  consultarReceta(i) {
    const initialState: any = {
      datos: this.productos[i],
    };

    this.ModalReferenciaPedidoReceta = this.modalServicioPedidoReceta.show(
      ModalPedidoRecetaComponent,
      {
        initialState,
        ignoreBackdropClick: true,
        class: 'modal-dialog-centered modal-xl',
      }
    );
    //Al cerrar el modal recibe la data
    this.ModalReferenciaPedidoReceta.content.event.subscribe((resultado) => {
      //console.log('MODAL DE PEDIDOS RECETAS CERRADO');
      //console.log(resultado.data);
      this.productos[i] = resultado.data; //<--Actualiza producto
    });
  }

  traerConsumidorFinal() {
    this.spinner.show();
    this.servicio
      .buscarCliente(this.rucConsumidorFinal, this.empresa)
      .subscribe(
        (data) => {
          if (data.length > 0) {
            this.cliente = data[0];
          }
          this.banderaSpinner = true;
          this.clienteCollapse = this.cliente.nombre.trim();
          localStorage.setItem('cliente', JSON.stringify(this.cliente.ruc.toString().trim()));
          // CARGA LINEAS
          let datos = {
            tipo: '003',
            empresa: this.empresa,
          };

          this.servicio.getLineas(datos).subscribe((data) => {
            this.lineas = data;
            //console.log('LINEAS', this.lineas);
            this.lineas.forEach((linea) => {
              let campos = {
                codigo: linea.codigo,
                usuario: '',
                empresa: this.empresa,
              };

              this.servicio.obtenerItemsLinea(campos).subscribe(
                (data) => {
                  if (data.length > 0) {
                    data.forEach((item) => {
                      item.cantidad = 0;
                      item.descuento = 0;
                      item.receta = [];
                      item.comentario = item.cantidad + ' Completo';

                      this.total = this.total + item.precio * item.cantidad;
                    });

                    this.productos = this.productos.concat(data);

                    //console.log('PRODUCTOS ' + linea.codigo, this.productos);
                    this.cargaLocalStorage(linea.codigo);
                  }
                  this.banderaSpinner = true;
                },
                (err) => {
                  this.banderaSpinner = true;
                  this.alerta(
                    'Error de conexión',
                    'Se perdió la conexión con el servidor',
                    'error'
                  );
                  console.log(err);
                }
              );
            });
          });
        },
        (err) => {
          this.banderaSpinner = true;
          this.alerta(
            'Error de conexión',
            'Se perdió la conexión con el servidor',
            'error'
          );
          console.log(err);
        }
      );
  }

  //Cargar datos del localStorage en la temporal
  cargaLocalStorage(linea) {
    if (localStorage.getItem(this.cliente.ruc.toString())) {
      let datosPersistentes = JSON.parse(
        localStorage.getItem(this.cliente.ruc.toString())
      );
      datosPersistentes.forEach((item) => {
        if (item.linea === linea) {
          let productos = item.productos;
          this.productos.forEach((producto) => {
            productos.forEach((elemento) => {
              if (producto.codigo === elemento.codigo) {
                producto.cantidad = elemento.cantidad;
                producto.receta = [];
                producto.comentario = item.cantidad + ' Completo';
                this.total = this.total + producto.precio * producto.cantidad;
              }
            });
          });
        }
      });
    }
  }

  traerConsumidor() {
    this.clienteold = this.cliente;
    this.spinner.show();
    this.servicio
      .buscarCliente(this.cliente.ruc.toString().trim(), this.empresa)
      .subscribe(
        (data) => {
          if (data.length > 0) {
            this.cliente = data[0];
          }
          this.banderaSpinner = true;
          this.clienteCollapse = this.cliente.nombre.trim();
          localStorage.setItem('cliente', JSON.stringify(this.cliente.ruc.toString().trim()));
          this.cargarDatosStorage();
        },
        (err) => {
          this.banderaSpinner = true;
          this.alerta(
            'Error de conexión',
            'Se perdió la conexión con el servidor',
            'error'
          );
          console.log(err);
        }
      );
  }

  //Si consumidor final esta en el input lo borra y guarda sus datos en LS
  borrarConsumidorFinal() {
    if (
      this.cliente.ruc.toString().trim() ===
      this.rucConsumidorFinal.toString().trim()
    ) {
      this.productosConsumidorFinal = JSON.parse(
        JSON.stringify(this.productos)
      );
      this.cliente = {};
    }
  }

  calcularValores(id, event) {
    this.limpiacampos();

    let producto = this.buscarProducto(id);
    producto.cantidad = event.target.value;

    this.actualizarEfectivo();

    this.guardarDatosStorage(producto);
  }

  calculaTotales() {
    this.limpiacampos();
    //console.log(this.productos);
    this.productos.forEach((producto) => {
      let val_desc = (producto.precio * producto.descuento) / 100;

      if (this.pordescupon > 0) {
        val_desc = (producto.precio * this.pordescupon) / 100;
      }

      this.sumatoria += producto.cantidad * producto.precio;
      this.descuento += producto.cantidad * val_desc;

      let tar = producto.cantidad * (producto.precio - val_desc);
      this.subtotal += tar;

      if (producto.porc_tarifa == '0') {
        this.tarifa0 += tar;
      } else {
        this.tarifa12 += tar;
      }
      if (producto.cantidad > 0) {
        this.cantpro = this.cantpro + 1;
      }
    });

    this.impuesto = this.tarifa12 * 0.12;
    this.total = this.subtotal + this.impuesto;
    this.calcularCambio();
  }

  //Crea el elemento en el array
  creaItemArray(item) {
    let datos = {
      ruc: item.ruc,
      nombre: item.nombre,
      direccion: item.direccion,
      telefono: item.telefono,
      correo: item.correo,
      empresa: this.empresa,
      sucursal: this.sucursal,
    };
    this.spinner.show();
    this.servicio.creaCliente(datos).subscribe(
      (data) => {
        //console.log(data);

        this.banderaSpinner = true;
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cargarSucursales() {
    this.servicio.getConfiguraciones('015', this.empresa).subscribe(
      (result) => {
        if (result.length > 0) {
          this.sucursales = result;
/*
          for (let clave in this.sucursales) {
            if (this.sucursales[clave].codigo.trim() == this.sucursal.trim()) {
              this.sucursal = this.sucursales[clave].codigo.trim();
              this.datosucursal = this.sucursales[clave].nombre.trim();
            }
          }
          */
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cargarTipoEntrega() {
    this.servicio.getConfiguraciones('029', this.empresa).subscribe(
      (result) => {
        console.log(result);
        if (result.length > 0) {
          this.tiposentregas = result;
          for (let ite in this.tiposentregas) {
            if (this.tiposentregas[ite].codigo.trim() == this.tipoentrega){
              this.tipoentrega = this.tiposentregas[ite].codigo.trim();
            }            
          }
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cargarServicioEntrega() {
    this.servicio.getConfiguraciones('027', this.empresa).subscribe(
      (result) => {
        console.log(result);
        if (result.length > 0) {
          this.serviciosentregas = result;
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cargarFormaPago() {
    this.servicio.getConfiguraciones('028', this.empresa).subscribe(
      (result) => {
        //console.log(result);
        if (result.length > 0) {
          this.formaspagos = result;
          for (let clave in this.formaspagos) {
            if (this.formaspagos[clave].codigo.trim() == this.formapago.trim())
              this.strformapago = this.formaspagos[clave].nombre.trim();
          }
        }
      },
      (err) => {
        this.banderaSpinner = true;
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  cargarRecetas(producto, resta = 0) {
    return new Promise((resolve, reject) => {
      this.servicio.buscarReceta(producto.codigo, '1').subscribe(
        (result) => {
          //console.log(result);
          for (let e = 0; e < result['length']; e++) {
            result[e]['solicitado'] = true;
          }

          for (let i = 0; i < producto.cantidad - resta; i++) {
            let newResult = [];
            result.forEach((val) => newResult.push(Object.assign({}, val)));
            producto.receta.push(newResult);
          }
          //console.log('intermedio');
          //console.log(producto);
          resolve(producto);
        },
        (err) => {
          this.banderaSpinner = true;
          this.alerta(
            'Error de conexión',
            'Se perdió la conexión con el servidor',
            'error'
          );
          console.log(err);
          reject(err);
        }
      );
    });
  }

  async verificarRecetas() {
    let promesas = [];
    await this.productos.forEach((element) => {
      if (element.cantidad > 0) {
        let longitud = element.receta.length;
        if (element.receta.length > 0) {
          if (element.receta.length > element.cantidad) {
            for (let i = 0; i < longitud - element.cantidad; i++) {
              console.log('verificarRecetas', i);
              element.receta.pop();
            }
          } else if (element.receta.length < element.cantidad)
            promesas.push(this.cargarRecetas(element, longitud));
        } else {
          promesas.push(this.cargarRecetas(element));
        }
      }
    });

    await Promise.all(promesas).then((resultados) => {
      return;
    });
  }


  /// INTEGRACION PAYPHONE
  solicitarPP(numfac, clitmp,ntotal) {
    console.log('PAGO PAYPHONE');

    if (this.cliente.ruc.slice(0, 10) === '9'.repeat(10)) {
      /*
      this.alerta(
        'Cliente No Encontrado',
        'cliente no existe en PayPhone',
        'warning'
      );
      */
    } else {
      let campp = {
        empresa: this.empresa,
        sucursal: this.sucursal,
        modulo: '002',
        tipo: '001',

        numero: numfac,

        valor: ntotal,
        telefono: clitmp.telefono,
        email: clitmp.correo,
      };
      console.log('*** PP ', campp);

      this.servicio.pp_cobro_link(campp).subscribe(
        (result) => {
          if (result.length > 0) {
            let climovil = "593" + clitmp.telefono.replace(/\s/g, '').substring(1, 10);

            if (climovil.length !== 12) {
              this.alerta("Error en Teléfono","Número de telefono incorrecto ","warning")
            } else {
              let men_wh = {
                mensaje : "👋 Hola, sigue el link \npara seguir con el pago *$"+ntotal.toFixed(2)+"*\n\nRecuerda que puedes pagar con Tarjeta de Credito / Debito\n\n"+"*En NovaExpress estamos para servirte.*",
                movil : climovil
              }              
              this.servicio.sms_wh(men_wh).subscribe((result) => {
                console.log(result)
              });

              let men_wh2 = {
                mensaje : result.trim(),
                movil : climovil
              }              
              this.servicio.sms_wh(men_wh2).subscribe((result) => {
                console.log(result)
              });

            }


            /* EN EL CASO DE QUE LOS CONSUMIDOR FINAL TAMBIEN COBREN 
          if (this.cliente.ruc.slice(0, 10) === '9'.repeat(10)) {
            Swal.fire({
              icon: 'success',
              title: 'Solicitud Enviada',
              html: `<b>PayPhone ID : </b>  <a href="${result.trim()}" target="_blank" >${result.trim()}</a>
                `,
              allowOutsideClick: false,
            });
          } else {
*/
            Swal.fire({
              icon: 'success',
              title: 'Procesar Pago $'+ntotal.toFixed(2),
              html: `<b>Cliente :</b> ${clitmp.nombre}<br>
              <b>Telefono :</b> ${clitmp.telefono}<br>
              <b>Email :</b> ${clitmp.correo}<br>
              <br>
              <br>
              <b>PayPhone ID : </b>  <a href="${result.trim()}" target="_blank" >${result.trim()}</a>
              `,
              allowOutsideClick: false,
              footer: 'Por favor, presiona el link para poder realizar el pago con Tarjeta de Credito / Debito ',
            });
            window.open(result.trim(), '_blank');
            /*} CONSUMIDOR FINAL  */
          }
        },
        (err) => {
          this.banderaSpinner = true;
          this.alerta(
            'Error de conexión',
            'Se perdió la conexión con el servidor',
            'error'
          );
          console.log(err);
        }
      );
    }
  }
  /// FIN INTEGRACION PAYPHONE
  /// CREA NUEVO CLIENTE

  abrirModalAgregarCliente(ruc = '') {
    const initialState: any = {
      datos: ['Cliente', ruc],
    };
    this.modalReferencia = this.modalServicio.show(ModalClienteaddComponent, {
      initialState,
      ignoreBackdropClick: true,
      class: 'modal-lg',
    });
    this.modalReferencia.content.event.subscribe((res) => {
      this.creaItemArray(res.data);
      this.cliente = res.data;
      this.clienteCollapse = this.cliente.nombre.trim();
    });
  }

  /// FIN DE CREA NUEVO CLIENTE

  alerta(titulo, texto, icono, tiempo = undefined) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      timer: tiempo,
      allowOutsideClick: false,
    });
  }
}
