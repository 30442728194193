import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-numero-mesa',
  templateUrl: './modal-numero-mesa.component.html',
  styleUrls: ['./modal-numero-mesa.component.css']
})
export class ModalNumeroMesaComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter(); 
  numeroEntero: number;


  constructor(public ModalNumeroMesa: BsModalRef
    ) { }

  ngOnInit(): void {
    this.numeroEntero = JSON.parse(localStorage.getItem('mesa'));
  }
  
  

  validarNumero(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }

    // Convertir this.numeroEntero a cadena
    const numeroCadena = this.numeroEntero;

    // Limitar la longitud máxima del número a 2 dígitos
    if (numeroCadena && numeroCadena.toString().length >= 2 && inputChar !== 'Backspace') {
      event.preventDefault();
    }
}

    grbnumero(){
      localStorage.setItem('mesa', JSON.stringify(this.numeroEntero));
      this.cerrarModal();
    }

    //Cerrar modal y enviar la data a factura
    cerrarModal() {
      this.lanzarEvento();
      this.ModalNumeroMesa.hide(); 
    }
  
    //Indicamos al padre que hemos devuelto algo, en este caso el modal cerrado
    lanzarEvento() {
      this.event.emit();
    }

}
