<!--Cabecera-->
<div class="row justify-content-center align-items-center my-2">
  <div class="col-9 col-md-10 py-2 text-left">
    <h5 class="m-0 p-0 ">
      Tipo de Servicio
      <small class="text-muted"></small>
    </h5>
  </div>
  <div
    class="col-2 col-md-1 justify-content-center align-items-center"
    (click)="cerrarModal()"
  >
    <button type="button" class="close pull-right" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<div class="container-fluid pb-4">
  <div class="row mb-3 justify-content-center">
    <div class="col-5 col-md-2" (click)="savets('002','Domicilio')">
      <div class="card">
        <img
          class="card-img-top"
          src="https://novaexpress.group/img/app_cli_qr/recursos/btn-casa.svg"
          alt="Card image cap"
          style="padding: 10%"
        />
        <div class="card-body">
          <h5 class="card-title text-center">Domicilio</h5>
        </div>
      </div>
    </div>

    <div class="col-5 col-md-2" (click)="savets('001','Local')">
      <div class="card">
        <img
          class="card-img-top"
          src="https://novaexpress.group/img/app_cli_qr/recursos/btn-local.svg"
          alt="Card image cap"
          style="padding: 10%"
        />
        <div class="card-body">
          <h5 class="card-title text-center">Local</h5>
        </div>
      </div>
    </div>
  </div>
</div>
