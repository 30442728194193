import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//RAIZ

import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

//VENTAS

import { FacturaComponent } from './components/restaurante/ventas/factura/factura.component';

//Importando rutas de las paginas
const routes: Routes = [
  { path: '', component: FacturaComponent },
  { path: 'proximamente', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
