import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GeolocalizacionService {
  public localizacionUser?: [number, number];

  get leerlocalizacionUser(): boolean {
    return !!this.localizacionUser;
  }

  constructor() {
    //this.getlocalizacionUser();
  }

  public async getlocalizacionUser(): Promise<[number, number]> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          this.localizacionUser = [coords.longitude, coords.latitude];
          //alert(this.localizacionUser);
          localStorage.setItem('GPS', JSON.stringify(this.localizacionUser));
          resolve(this.localizacionUser);
        },
        (err) => {
          alert('Lamentablemente no logramos obtener tu ubicacion');
          console.log(err);
          reject();
        }
      );
    });
  }
}
