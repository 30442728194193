<!--Cabecera-->
<div class="row justify-content-center align-items-center my-2">
    <div class="col-9 col-md-10 py-2 text-left">
      <h5 class="m-0 p-0 ">
        Sucursal
        <small class="text-muted"></small>
      </h5>
    </div>
    <div
      class="col-2 col-md-1 justify-content-center align-items-center"
      (click)="cerrarModal()"
    >
      <button type="button" class="close pull-right" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

<!--LISTADO DE SUCURSALES-->
  <div class="container-fluid pb-4">
    <div class="row mb-3 justify-content-center">
      <div class="col-12 py-1" *ngFor="let itemsuc of sucursales">
        <div class="card btn-outline-primary " style="border-bottom-style: solid" (click)="saveSuc(itemsuc)">
            <div class="card-body p-2">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-2">
                            <i class="fas fa-store"></i>
                        </div>
                        <div class="col-10">
                            <h6 class="m-0 py-1">{{ itemsuc.nombre }}</h6>
                        </div>
                    </div>
                </div>            
            </div>
       </div>
      </div>
    </div>
  </div>