<!--CABECERA-->
<div class="container-fluid my-2 py-3">
  <div class="row justify-content-center align-items-center">
    <div class="col-4">
      <div class="card btn-outline-dark p-0"
       style="border-bottom-style: solid"
              (click)="mostrarModalTipoServicio()">
              <div class="card-body p-0 py-2 text-center">
                <i class="fab fa-readme"></i>
                <h6 class="m-0">Servicio</h6>
              </div>
              <div class="card-footer p-0 text-center bg-dark">
                <h6 class="text-light m-0 py-1">{{ nomservicio}}</h6>
              </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card btn-outline-primary p-0"
       style="border-bottom-style: solid"
              (click)="mostrarModalSucursal()">
              <div class="card-body p-0 py-2 text-center">
                <i class="fas fa-store"></i>
                <h6 class="m-0">Sucursal</h6>
              </div>
              <div class="card-footer p-0 text-center bg-primary">
                <h6 class="text-light m-0 py-1">{{ nomsucursal }}</h6>
              </div>
      </div>
    </div>
    <div class="col-4" *ngIf="tipoentrega==='001' && mesa>0">
      <div class="card btn-outline-secondary p-0"
       style="border-bottom-style: solid"
              (click)="mostrarModalMesa()">
              <div class="card-body p-0 py-2 text-center">
                <i class="fas fa-utensils"></i>
                <h6 class="m-0">Mesa</h6>
              </div>
              <div class="card-footer p-0 text-center bg-secondary">
                <h6 class="text-light m-0 py-1">{{ mesa }}</h6>
              </div>
      </div>
    </div>
  </div>
</div>

<!--MOVIMIENTOS-->
<div class="container-fluid" *ngIf="lineas.length">
  <div class="row justify-content-center">
    <div
      class="col-12 col-sm-6 col-lg-4 col-xl-3 my-3"
      *ngFor="let linea of lineas"
    >
      <div class="card h-100" style="background-color: #14477a">
        <img
          [src]="linea.urllogo"
          alt="plato"
          class="card-img-top img-fluid"
          onerror="this.onerror=null;this.src='https://novaexpress.group/img/app_cli_qr/banners/logo.jpg';"
        />

        <div class="card-body m-0 p-1 py-4 let">
          <h5
            class="card-title bloquea-select text-center m-0 p-0 pb-3 let"
            style="color: #f1c40f"
            (click)="mostrarModalLinea(linea.codigo)"
          >
            {{ linea.nombre }}
          </h5>

          <!--PLATOS DE LA LINEA-->
          <div class="container-fluid">
            <div class="row">
              <div class="col-12" *ngFor="let producto of productos">
                <div
                  class="card my-1"
                  id="{{ producto.codigo }}"
                  *ngIf="producto.linea == linea.codigo"
                  [ngClass]="{ 'plato-sel': producto.cantidad > 0 }"
                  style="background-color: #14477a; border: none"
                >
                  <!-- SIN SELECCCION -->
                  <div
                    class="row d-flex justify-content-center align-items-center"
                    *ngIf="producto.cantidad == 0"
                  >
                    <div class="col-8 let">
                      <h5 class="bloquea-select pl-2 m-0" style="color: #fff">
                        {{ producto.nombre }}
                      </h5>
                    </div>

                    <div class="col-2 text-right m-0 p-0">
                      <span class="badge badge-light bloquea-select text-right">
                        $ {{ producto.precio.toFixed(2) }}</span
                      >
                    </div>

                    <div class="col-2">
                      <button
                        type="button"
                        class="btn btn-success btn-circle p-0"
                        (click)="aumentarCantidad(producto.codigo)"
                        style="background-color: #e2652a; border-color: #fff"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>

                  <!--YA ALMENOS HAY 1-->
                  <div class="row no-gutters" *ngIf="producto.cantidad > 0">
                    <div class="col-12 d-flex align-items-center">
                      <div class="card-body m-0 p-1">
                        <!--btn elimina y nombre-->
                        <div class="col-12 p-0 m-0">
                          <div class="container-fluid p-1">
                            <div class="row p-0 m-0">
                              <div class="col-12 p-0 m-0 text-center let">
                                <h5 class="p-0 m-0 bloquea-select">
                                  {{ producto.nombre }}
                                  <span
                                    class="badge badge-success bloquea-select"
                                    *ngIf="producto.porc_tarifa > 0"
                                    >{{
                                      producto.porc_tarifa.toFixed(0)
                                    }}
                                    %</span
                                  >
                                </h5>
                              </div>
                            </div>
                            <!--btn cantidad, precio y receta-->
                            <div
                              class="row d-flex justify-content-center align-items-center my-1"
                            >
                              <div class="col-3">
                                <p class="p-0 m-0" style="color: #14477a">
                                  <strong>
                                    $ {{ producto.precio.toFixed(2) }}
                                  </strong>
                                </p>
                              </div>
                              <div
                                class="col-6 d-flex justify-content-center align-items-center"
                              >
                                <button
                                  type="button"
                                  class="btn btn-outline-danger btn-circle p-0"
                                  (click)="restarCantidad(producto.codigo)"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-dash"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"
                                    />
                                  </svg>
                                </button>
                                <div
                                  style="min-width: 50px"
                                  class="text-center d-flex align-items-center justify-content-center"
                                >
                                  <p
                                    id="{{ producto.codigo }}"
                                    style="color: #e2652a"
                                    class="font-weight-bold m-0"
                                  >
                                    {{ producto.cantidad }}
                                  </p>
                                </div>

                                <button
                                  type="button"
                                  class="btn btn-success btn-circle p-0"
                                  (click)="aumentarCantidad(producto.codigo)"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-plus"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                                    />
                                  </svg>
                                </button>
                              </div>

                              <div class="col-3 pl-0 text-right align-middle">
                                <p class="p-0 m-0" style="color: #14477a">
                                  <strong
                                    >$
                                    {{
                                      (
                                        producto.precio * producto.cantidad +
                                        (producto.precio *
                                          producto.cantidad *
                                          producto.porc_tarifa) /
                                          100
                                      ).toFixed(2)
                                    }}</strong
                                  >
                                </p>
                                <span
                                  class="badge badge-dark"
                                  *ngIf="producto.porc_tarifa > 0"
                                  >incl.IVA</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--DATOS CLIENTE-->
<div class="container-fluid py-2">
  <div class="row justify-content-center">
    <!--colapsable del cliente-->
    <div class="col-11 col-md-6 col-lg-3 my-2 p-0">
      <div class="card p-0">
        <div class="card-header m-0 p-0 py-1" (click)="abrirCollapseCliente()">
          <div class="container-fluid">
            <div class="row">
              <div class="col-10 py-1">
                <h6 class="m-0 bloquea-select">
                  {{ clienteCollapse }}
                </h6>
              </div>
              <div class="col-2 text-right">
                <span
                  ><i id="datos_cliente_icon" class="fas fa-chevron-down"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body p-2">
          <div class="container p-0">
            <div class="row p-0 justify-content-center">
              <div class="col-11 p-0">
                <div class="input-group my-1">
                  <div class="input-group-prepend">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-success"
                      (click)="abrirModalAgregarCliente()"
                    >
                      <i class="far fa-plus-square"></i>
                    </button>
                  </div>
                  <div class="input-group-prepend">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-dark"
                      (click)="traerConsumidorFinal()"
                    >
                      <i class="fas fa-house-user"></i>
                    </button>
                  </div>
                  <div
                    class="input-group-prepend text-center"
                    (click)="abrirCollapseCliente()"
                  >
                    <span class="input-group-text" id="ruc"
                      ><i class="fas fa-id-card-alt"></i
                    ></span>
                  </div>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Cedula | Ruc | Nombre"
                    [(ngModel)]="cliente.ruc"
                  />
                  <div class="input-group-prepend">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-success"
                      (click)="traerConsumidor()"
                    >
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="datos_cliente_contenido" style="display: none">
            <div *ngIf="cliente.nombre">
              <div class="input-group my-1">
                <div class="input-group-prepend text-center">
                  <span class="input-group-text" id="nombre"
                    ><i class="far fa-id-card"></i
                  ></span>
                </div>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Nombre Completo"
                  [(ngModel)]="cliente.nombre"
                />
              </div>

              <div class="input-group my-1">
                <div class="input-group-prepend text-center">
                  <span class="input-group-text" id="direccion"
                    ><i class="fas fa-map-marked-alt"></i
                  ></span>
                </div>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Direccion"
                  [(ngModel)]="cliente.direccion"
                />
              </div>

              <div class="input-group my-1">
                <div class="input-group-prepend text-center">
                  <span class="input-group-text" id="telefono"
                    ><i class="fab fa-whatsapp"></i
                  ></span>
                </div>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Telefonos"
                  [(ngModel)]="cliente.telefono"
                />
              </div>

              <div class="input-group my-1">
                <div class="input-group-prepend text-center">
                  <span class="input-group-text" id="correo"
                    ><i class="fas fa-envelope-open-text"></i
                  ></span>
                </div>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Correo Electronico"
                  [(ngModel)]="cliente.correo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--PRODUCTOS YA PEDIDOS-->
<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-6 col-xl-4 py-1">
      <!--Detalle de la Factura-->
      <div class="row justify-content-center" *ngIf="total > 0">
        <div class="col-11 p-0">
          <div class="card">
            <div class="card-header py-1">
              <div class="container-fluid">
                <!--Cabecera del movimiento-->
                <div
                  class="row justify-content-center align-items-center p-0 m-0"
                >
                  <!--Solo para moviles-->
                  <!--d-block d-lg-none-->
                  <div class="col-12 p-0 m-0 text-center font-weight-bold">
                    ({{ cantpro }}) 🛒 Detalle del Pedido - $
                    {{ total.toFixed(2) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body p-2">
              <div
                class="m-0 p-0"
                *ngFor="
                  let producto of productos
                    | paginate
                      : {
                          itemsPerPage: 100000,
                          currentPage: p,
                          totalItems: productos.count
                        };
                  index as i
                "
              >
                <div
                  class="card"
                  *ngIf="producto.cantidad > 0"
                  id="{{ producto.codigo }}"
                >
                  <div class="container-fluid">
                    <div class="row no-gutters">
                      <div class="col-12 d-flex align-items-center">
                        <div class="card-body m-0 p-1">
                          <!--btn elimina y nombre-->
                          <div class="col-12 p-0 m-0">
                            <div class="container-fluid p-1">
                              <div class="row p-0 m-0">
                                <!--
                                <div class="col-2 p-0 m-0">
                                  <button
                                    type="button"
                                    class="btn btn-outline-warning btn-sm"
                                    (click)="borrarItem(producto.codigo)"
                                  >
                                    <i class="far fa-trash-alt"></i>
                                  </button>
                                </div>
                                <div class="col-2 p-0 m-0">
                                  <button
                                    type="button"
                                    class="btn btn-outline-dark btn-sm"
                                    (click)="consultarReceta(i)"
                                  >
                                    <i class="fas fa-tasks"></i>
                                  </button>
                                </div>
-->
                                <div class="col-12 p-0 m-0 text-left">
                                  <h6 class="p-0 m-0">
                                    {{ producto.nombre }}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!--btn cantidad, precio y receta-->
                          <div
                            class="row d-flex justify-content-center align-items-center my-1"
                          >
                            <div class="col-3">
                              <p class="p-0 m-0" style="color: #14477a">
                                <strong>
                                  $ {{ producto.precio.toFixed(2) }}
                                </strong>
                              </p>
                            </div>
                            <div
                              class="col-6 d-flex justify-content-center align-items-center"
                            >
                              <button
                                type="button"
                                class="btn btn-outline-danger btn-circle p-0"
                                (click)="restarCantidad(producto.codigo)"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-dash"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"
                                  />
                                </svg>
                              </button>
                              <div
                                style="min-width: 50px"
                                class="text-center d-flex align-items-center justify-content-center"
                              >
                                <p
                                  id="{{ producto.codigo }}"
                                  style="color: #e2652a"
                                  class="font-weight-bold m-0"
                                >
                                  {{ producto.cantidad }}
                                </p>
                              </div>

                              <button
                                type="button"
                                class="btn btn-success btn-circle p-0"
                                (click)="aumentarCantidad(producto.codigo)"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-plus"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                                  />
                                </svg>
                              </button>
                            </div>

                            <div class="col-3 pl-0 text-right align-middle">
                              <p class="p-0 m-0" style="color: #14477a">
                                <strong
                                  >$
                                  {{
                                    (
                                      producto.precio * producto.cantidad +
                                      (producto.precio *
                                        producto.cantidad *
                                        producto.porc_tarifa) /
                                        100
                                    ).toFixed(2)
                                  }}</strong
                                >
                              </p>
                              <span
                                class="badge badge-dark"
                                *ngIf="producto.porc_tarifa > 0"
                                >incl.IVA</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row justify-content-center align-items-center"
        *ngIf="total > 0"
      >
        <div class="col-12 col-md-6 py-1">
          <mat-form-field appearance="outline" class="w-100 input_sin_pad">
            <mat-label>Comentario</mat-label>
            <textarea
              matInput
              type="text"
              [(ngModel)]="comentario"
              rows="2"
            ></textarea>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-5 py-2">
          <mat-form-field appearance="outline" class="w-100 input_sin_pad">
            <mat-label>Cupón de Descuento</mat-label>
            <input matInput type="text" [(ngModel)]="cupon" />
            <button
              *ngIf="cupon"
              matSuffix
              mat-icon-button
              (click)="buscar_descuento()"
              style="
                background-color: #14477a;
                color: #fff;
                max-height: 25px;
                max-width: 25px;
              "
            >
              <mat-icon>manage_search</mat-icon>
            </button>
          </mat-form-field>
          <div
            class="alert alert-success alert-dismissible fade show m-0 mt-1"
            role="alert"
            *ngIf="this.valdescupon > 0"
          >
            Cupón : <strong>{{ this.aplicacupon }}</strong> =
            {{ this.pordescupon }}%<br />
            Descuento = $ {{ this.descuento.toFixed(2) }}
            <button type="button" class="close" (click)="quitar_descuento()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>

        <!--FORMA DE PAGO-->
 <!--
        <div class="col-12 col-md-5 col-lg-4 py-2">
          <div
            class="card btn-outline-success p-0"
            style="
              border-bottom-style: solid;
              background-color: #14477a;
              color: white;
            "
            (click)="mostrarModalFormaPago()"
          >
            <div class="card-body p-0 text-center" style="min-height: 65px">
              <h5 class="mt-2 m-0">🛒 $ {{ total.toFixed(2) }}</h5>
              <h6 *ngIf="formapago === ''">Forma de Pago</h6>
              <h6 *ngIf="formapago != ''">{{ formapago }}</h6>
            </div>
          </div>
        </div>
      -->
        <div class="col-12 col-md-5 col-lg-4 py-1 p-0">
          <div
            class="card btn-outline-success p-0"
            style="
              border-bottom-style: solid;
              background-color: #14477a;
              color: white;
              min-height: 65px;
            "
            (click)="grabar()"
          >
            <div
              class="card-body p-0 d-flex align-items-center justify-content-center"
            >
              <h6 class="m-0 p-0">Solicitar Pedido</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-spinner></app-spinner>
