<!--Cabecera-->
<div class="row justify-content-center align-items-center my-2">
    <div class="col-9 col-md-10 py-2 text-left">
      <h5 class="m-0 p-0 ">
        Número de Mesa
        <small class="text-muted"></small>
      </h5>
    </div>
    <div
      class="col-2 col-md-1 justify-content-center align-items-center"
      (click)="cerrarModal()"
    >
      <button type="button" class="close pull-right" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>

  <div class="container-fluid py-4">
    <div class="row">
        <div class="col-12 py-3">
            <input class="form-control input-lg text-center" 
            type="text" 
            [(ngModel)]="numeroEntero" 
            (keypress)="validarNumero($event)"
            maxlength="2"
            style="font-size: 125px;"
            >
        </div>
        <div class="col-12 pb-4" (click)="grbnumero()">
          <div class="card btn-outline-dark p-0" 
          style="border-bottom-style: solid" 
          >
              <div class="card-body p-0 py-2 text-center">
                <i class="fas fa-check-circle"></i>
              </div>
              <div class="card-footer p-0 text-center bg-dark">
                <h6 class="text-light m-0 py-1">Aceptar</h6>
              </div>
            </div>
        </div>
    </div>
  </div>
  