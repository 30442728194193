<div
  class="d-flex contenido-notificacion"
  [style.background-color]="empresa.color + 'FA'"
  style="align-items: center; height: 50px"
>
  <div class="ml-4 py-2 pr-2" style="color: white">
    <i
      class="fas fa-bars boton"
      (click)="abrirMenu()"
      style="font-size: 1.3rem"
    ></i>
  </div>

  <img [src]="empresa.urlLogo" class="imagen" alt="Empresa Logo" />

  <div class="informacion bloquea-select">
    <div class="mr-3 boton" style="color: white" (click)="cerrarNotificacion()">
      <i class="fas fa-comment-alt"></i>
    </div>
    <div
      class="mr-3 esconder"
      [style.color]="empresa.color"
      style="background-color: white; border-radius: 24px; padding: 0.4rem 1rem"
    >
      <h6 style="margin: 0">
        <i class="fas fa-map mr-2"></i> {{ sucursal.nombre }}
      </h6>
    </div>

    <div
      class="mr-3 esconder"
      [style.color]="empresa.color"
      style="background-color: white; border-radius: 24px; padding: 0.4rem 1rem"
    >
      <h6 style="margin: 0">
        <i class="fas fa-user mr-2"></i> {{ usuario.nombre }}
      </h6>
    </div>
    <div
      class="boton"
      [style.color]="empresa.color"
      style="
        background-color: white;
        border-radius: 24px;
        padding: 0.4rem 0.4rem;
      "
      (click)="cerrarSesion()"
    >
      <h6 style="margin: 0"><i class="fas fa-door-open"></i></h6>
    </div>
  </div>

  <!--<app-menu></app-menu> -->
</div>
<div class="container-fluid p-0" *ngIf="empresa.acceso != ''">
  <div class="row">
    <div class="col-12">
      <div class="alert alert-danger text-center font-weight-bold">
        {{ empresa.acceso }}
      </div>
    </div>
  </div>
</div>
