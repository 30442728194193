import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './components/menu/menu.component';

import { RestauranteService } from '../app/services/servicio-restaurante/servicio-restaurante.service';
import { HttpClientModule } from '@angular/common/http';

import { ModalModule } from 'ngx-bootstrap/modal';

import { NgxPaginationModule } from 'ngx-pagination';
import { FacturaComponent } from './components/restaurante/ventas/factura/factura.component';

import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpinnerComponent } from './components/spinner/spinner.component';

import { OnlynumberDirective } from '../../src/app/onlynumber.directive';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';

import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';

import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatTabsModule } from '@angular/material/tabs';
import { BarraMenuComponent } from './components/barra-menu/barra-menu.component';
import { PanelNotificacionesComponent } from './components/panel-notificaciones/panel-notificaciones.component';

import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

import { NgxMaskModule, IConfig } from 'ngx-mask';

import { ModalLineasComponent } from './components/restaurante/modal-lineas/modal-lineas.component';
import { ModalFormapagoComponent } from './components/restaurante/modal-formapago/modal-formapago.component';

import { ModalPedidoRecetaComponent } from './components/restaurante/modal-pedido-receta/modal-pedido-receta.component';
import { ModalClienteaddComponent } from './components/restaurante/modal-clienteadd/modal-clienteadd.component';

import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';

import { ModalTipoServicioComponent } from './components/restaurante/ventas/modal-tipo-servicio/modal-tipo-servicio.component';
import { ModalLocalComponent } from './components/restaurante/ventas/modal-local/modal-local.component';
import { ModalNumeroMesaComponent } from './components/restaurante/ventas/modal-numero-mesa/modal-numero-mesa.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FacturaComponent,
    SpinnerComponent,
    OnlynumberDirective,
    BarraMenuComponent,
    PanelNotificacionesComponent,
    ModalLineasComponent,
    PageNotFoundComponent,
    ModalFormapagoComponent,
    ModalPedidoRecetaComponent,
    ModalClienteaddComponent,
    ModalTipoServicioComponent,
    ModalLocalComponent,
    ModalNumeroMesaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatSliderModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatTabsModule,
    MatCheckboxModule,
    MatIconModule,
    MatChipsModule,
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
  ],
  providers: [
    RestauranteService,
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
