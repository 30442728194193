import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-formapago',
  templateUrl: './modal-formapago.component.html',
  styleUrls: ['./modal-formapago.component.css'],
})
export class ModalFormapagoComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter(); //<-- En este caso lo
  lcformapago: any;
  cliente : any;

  constructor(public modalReferenciaFormaPago: BsModalRef) {}

  ngOnInit(): void {

    if (JSON.parse(localStorage.getItem('cliente')) !== null) {
      this.cliente = JSON.parse(localStorage.getItem('cliente'));
    }

    if (JSON.parse(localStorage.getItem('formapago')) !== null) {
      this.lcformapago = JSON.parse(localStorage.getItem('formapago'));
    }
  }

  savefp(fp) {
    localStorage.setItem('formapago', JSON.stringify(fp));

    switch (fp) {
      case 'Pay Phone':
        //window.open('https://www.payphone.app/', '_blank');
        break;
      case 'De Una':
        window.open('https://deuna.onelink.me/', '_blank');
        break;
      case 'BiVi':
        window.open('https://www.biviapp.com/', '_blank');
        break;
      case 'Banco Internacional':
        window.open('https://www.bancointernacional.com.ec/', '_blank');
        break;
    }

    this.cerrarModal();
  }

  //Cerrar modal y enviar la data a factura
  cerrarModal() {
    this.lanzarEvento();
    /*if (this.agregoItems)
      this.alerta('Éxito', 'Productos agregados', 'success').then(() => {
        this.modalReferenciaLineas.hide(); //<-- Cierra el modal
      });
    else*/
    this.modalReferenciaFormaPago.hide(); //<-- Cierra el modal
  }

  //Indicamos al padre que hemos devuelto algo, en este caso el modal cerrado
  lanzarEvento() {
    this.event.emit();
  }
}
