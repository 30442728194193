import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RestauranteService } from 'src/app/services/servicio-restaurante/servicio-restaurante.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-modal-local',
  templateUrl: './modal-local.component.html',
  styleUrls: ['./modal-local.component.css']
})
export class ModalLocalComponent implements OnInit {

  empresa: string = '001';
  sucursales: any;
  datosucursal: string = 'VILLA CLUB';
  sucursal: string = '002';

  public event: EventEmitter<any> = new EventEmitter(); //<-- En este caso lo

  constructor(public ModalLocal: BsModalRef,private servicio: RestauranteService,) {}

  ngOnInit(): void {
    this.cargarSucursales() 
  }

  saveSuc(ts) {
    localStorage.setItem('sucursal', JSON.stringify(ts));

    this.cerrarModal();
  }
  //Cerrar modal y enviar la data a factura
  cerrarModal() {
    this.lanzarEvento();
    this.ModalLocal.hide(); 
  }

  cargarSucursales() {
    this.servicio.getConfiguraciones('015', this.empresa).subscribe(
      (result) => {
        if (result.length > 0) {
          this.sucursales = result;
          console.log(this.sucursales)
          for (let cp in this.sucursales) {
            for (let clave in this.sucursales) {
              if (this.sucursales[clave].codigo.trim() === '008' || this.sucursales[clave].codigo.trim() === '009' ){
                this.sucursales.splice(clave, 1); // Elimina 1 elemento en el índice 2
              }
            }
          }
          console.log(this.sucursales)
      }
      },
      (err) => {
        this.alerta(
          'Error de conexión',
          'Se perdió la conexión con el servidor',
          'error'
        );
        console.log(err);
      }
    );
  }

  //Indicamos al padre que hemos devuelto algo, en este caso el modal cerrado
  lanzarEvento() {
    this.event.emit();
  }

  alerta(titulo, texto, icono, tiempo = undefined) {
    return Swal.fire({
      icon: icono,
      title: titulo,
      text: texto,
      timer: tiempo,
      allowOutsideClick: false,
    });
  }
}
