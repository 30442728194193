import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-tipo-servicio',
  templateUrl: './modal-tipo-servicio.component.html',
  styleUrls: ['./modal-tipo-servicio.component.css'],
})
export class ModalTipoServicioComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter(); 

  constructor(public modalTipoServicio: BsModalRef) {}

  ngOnInit(): void {}

  savets(ts,ns) {
    let datos = {
      codigo : ts,
      nombre : ns
    }

    localStorage.setItem('tiposervicio', JSON.stringify(datos));

    this.cerrarModal();
  }
  //Cerrar modal y enviar la data a factura
  cerrarModal() {
    this.lanzarEvento();
    this.modalTipoServicio.hide(); 
  }

  //Indicamos al padre que hemos devuelto algo, en este caso el modal cerrado
  lanzarEvento() {
    this.event.emit();
  }
}
