import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  //public servidor: string = 'http://localhost:4000';
  public servidor: string = 'https://novaexpress-42f6b816772c.herokuapp.com';

  constructor(private http: HttpClient) {}

  //Cargar configuraciones LINEA y GRUPO omitiendo un elemento
  getMenu(campos): Observable<any> {
    return this.http.get<any>(
      `${this.servidor}/api/menus?empresa=${campos.empresa}&sucursal=${campos.sucursal}&empleado=${campos.empleado}`
    );
  }

  cambiarPassword(campos) {
    return this.http.post(
      this.servidor + `/api/usuarios/cambiarPassword`,
      campos
    );
  }

  login(campos) {
    return this.http.get(
      this.servidor +
        `/api/usuarios/perfil?user=${campos.usuario}&pass=${campos.password}&opcion=${campos.opcion}`
    );
  }
}
