<!--Cabecera-->
<div class="row justify-content-center align-items-center my-2">
  <div class="col-9 col-md-10 text-center">
    <h5 class="m-0 p-0">
      {{ nomLinea }}
      <small class="text-muted"></small>
    </h5>
  </div>
  <div
    class="col-2 col-md-1 justify-content-center align-items-center"
    (click)="cerrarModal()"
  >
    <button type="button" class="close pull-right" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<div
  class="row justify-content-center align-items-center"
  *ngIf="hayProductos === true; else sinProductos"
>
  <div class="col-12 col-md-11">
    <div
      class="row justify-content-center justify-content-md-start align-items-center"
    >
      <div
        class="col-11 col-sm-6 col-md-4 col-lg-3 py-3"
        *ngFor="let producto of productos"
      >
        <div class="card my-1" id="{{ producto.codigo }}">
          <div class="row no-gutters">
            <div class="col-4" (click)="aumentarCantidad(producto.codigo)">
              <img
                class="img-fluid"
                src="https://novaexpress.group/img/logos/pez_express_color_cuadrado.svg"
              />
            </div>
            <div class="col-8 d-flex align-items-center">
              <div class="card-body m-0 p-1">
                <!--btn elimina y nombre-->
                <div class="col-12 p-0 m-0">
                  <div class="container-fluid p-1">
                    <div class="row p-0 m-0">
                      <div class="col-12 p-0 m-0 text-left">
                        <h6 class="p-0 m-0 bloquea-select">
                          {{ producto.nombre }}
                        </h6>
                      </div>
                      <div class="col-6 p-0 m-0">
                        <h6 class="p-0 m-0">
                          <span class="badge badge-primary bloquea-select"
                            >PVP : $ {{ producto.precio.toFixed(2) }}</span
                          >
                        </h6>
                      </div>
                      <div
                        class="col-6 p-0 m-0"
                        *ngIf="producto.porc_tarifa > 0"
                      >
                        <h6>
                          <span class="badge badge-secondary bloquea-select"
                            >{{ producto.porc_tarifa.toFixed(0) }} %</span
                          >
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <!--btn cantidad, precio y receta-->
                <div class="col-12 p-0 m-0">
                  <div class="container-fluid p-1">
                    <div class="row p-0 m-0">
                      <div class="col-8 col-md-12 col-lg-7 p-0 m-0">
                        <div class="input-group">
                          <div
                            class="input-group-prepend"
                            (click)="restarCantidad(producto.codigo)"
                          >
                            <button
                              type="button"
                              class="btn btn-outline-danger btn-sm"
                            >
                              <i class="fas fa-minus"></i>
                            </button>
                          </div>
                          <input
                            style="text-align: right"
                            type="numeric"
                            class="form-control form-control-sm text-right"
                            placeholder="{{ producto.cantidad }}"
                            [(ngModel)]="producto.cantidad"
                            appOnlynumber
                            (keyup)="calcularValores(producto.codigo, $event)"
                          />
                          <div
                            class="input-group-append"
                            (click)="aumentarCantidad(producto.codigo)"
                          >
                            <button
                              type="button"
                              class="btn btn-outline-success btn-sm"
                            >
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-4 col-md-12 col-lg-5 p-0 m-0 text-right align-middle"
                      >
                        <p
                          style="font-size: 1.2rem"
                          class="text-success p-0 m-0"
                        >
                          <strong
                            >$
                            {{
                              (
                                producto.precio * producto.cantidad +
                                (producto.precio *
                                  producto.cantidad *
                                  producto.porc_tarifa) /
                                  100
                              ).toFixed(2)
                            }}</strong
                          >
                        </p>
                        <span
                          class="badge badge-dark"
                          *ngIf="producto.porc_tarifa != '0'"
                          >incl.IVA</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #sinProductos>
  <ng-container *ngIf="hayProductos === false"
    >No existen Items en esta seccion.</ng-container
  >
</ng-template>

<app-spinner></app-spinner>
