import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuComponent } from './components/menu/menu.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  @ViewChild(MenuComponent) child;
  title = 'NovaExpress Group';
  menu = false;
  notificacion = false;

  constructor(private router: Router) {}

  ngOnInit() {
    setInterval(() => {
      this.verficals();
    }, 100);

    if (!localStorage.getItem('sucursal')) {
      this.menu = false;
      return;
    }

    this.verficals();
    this.router.navigate(['/']);
  }
  verficals() {
    this.notificacion = false;
    if (JSON.parse(localStorage.getItem('notificacion')) !== null) {
      if (localStorage.getItem('notificacion') == 'true')
        this.notificacion = true;
    }
  }
  cerrarMenu() {
    //console.log(this.child);
    //if (this.child) this.child.hidden = false;
  }
}
