<div
  class="container contenido-notificacion animacion pt-3 bloquea-select"
  [class]="hidden ? 'abierto' : 'cerrado'"
  style="background-color: rgb(233, 233, 233); height: 100%"
>
  <div class="">
    <i class="fas fa-times boton p-2" (click)="cerrarNotificacion()"></i>
  </div>

  <div
    class="mb-3 tarjeta-notificacion"
    *ngFor="let notificacion of notificaciones; index as i"
    [style.background-color]="notificacion.colorfondo"
  >
    <div class="d-flex boton" (click)="abrirNotificacion(i)">
      <div style="width: 90%" [style.text-align]="notificacion.align">
        <p style="margin: 0" [style.color]="notificacion.coloreti">
          <i
            class="mr-2"
            [class]="notificacion.icono"
            [style.color]="notificacion.colorico"
          >
          </i>

          {{ notificacion.etiqueta }}
        </p>
      </div>

      <div style="width: 10%">
        <div
          class="d-flex"
          style="justify-content: center; align-items: center; height: 100%"
        >
          <i
            class="fas fa-chevron-right"
            [style.color]="notificacion.colorico"
            *ngIf="!notificacion.abierto"
          ></i>
          <i
            class="fas fa-chevron-down"
            [style.color]="notificacion.colorico"
            *ngIf="notificacion.abierto"
          ></i>
        </div>
      </div>
    </div>
    <div
      class="animacion animacion_contenido contenido_notificacion"
      [style.max-height]="notificacion.abierto ? '500px' : '0px'"
    >
      <div *ngFor="let movimiento of notificacion.movimientos">
        <div [style.background-color]="movimiento.color">
          <h6 style="margin: 0">
            {{ movimiento.titulo }}
          </h6>
          <p class="font-weight-bold" style="margin: 0">
            {{ movimiento.subtitulo }}
          </p>
          <p style="margin: 0">{{ movimiento.mensaje }}</p>
          <p></p>
        </div>
        <hr style="margin-top: 0" />
      </div>
      <div *ngIf="notificacion.movimientos == 0" class="text-center">
        <h6 style="margin: 0">Sin notificaciones</h6>
      </div>
    </div>
  </div>
</div>
