import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
@Component({
  selector: 'app-panel-notificaciones',
  templateUrl: './panel-notificaciones.component.html',
  styleUrls: ['./panel-notificaciones.component.css'],
})
export class PanelNotificacionesComponent implements OnInit {
  notificaciones: any = [];
  hidden = false;
  //constructor(private servicio: ServicioMeseroService) {}
  constructor(private servicio: GlobalService) {}
  ngOnInit(): void {
    if (localStorage.getItem('usuario')) this.cargarNotificaciones();
    // cada 30 seg buscara nuevas notificaciones
    setInterval(() => {
      if (localStorage.getItem('usuario')) this.cargarNotificaciones();
    }, 1000 * 30);
  }

  cargarNotificaciones() {
    var today = new Date();
    var time =
      today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    //console.log(today, time);
    let datos = {
      empresa: JSON.parse(localStorage.getItem('empresa')).codigo,
      sucursal: JSON.parse(localStorage.getItem('sucursal')).codigo,
      perfil: JSON.parse(localStorage.getItem('usuario')).codigo,
    };
    //console.log(datos);
    /*this.servicio.obtenerNotificaciones(datos).subscribe((data) => {
      //console.log(data);
      if (data.length > 0) {
        data.forEach((element) => {
          element.abierto = true;
        });
        this.notificaciones = data;
      }
    });
    */
  }

  ngDoCheck() {
    if (localStorage.getItem('notificacion')) {
      if (localStorage.getItem('notificacion') == 'true') this.hidden = true;
      else this.hidden = false;
    } else {
      this.hidden = true;
    }
  }

  abrirNotificacion(i) {
    this.notificaciones[i].abierto = !this.notificaciones[i].abierto;
  }

  cerrarNotificacion() {
    localStorage.setItem('notificacion', 'true');
  }
}
